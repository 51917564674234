<template>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-card class="mb-0">
            <h5>
              <router-link :to="{ name: 'Case', params: { id: item.id }}" target="_blank"><h5>{{item.name}}</h5></router-link>
            </h5>
              <div class="mb-0"><small>
                <span v-if="item.created_at">record created: {{new Intl.DateTimeFormat().format(new Date(item.created_at))}} |</span>
                <span v-if="item.date_published">news published: {{new Intl.DateTimeFormat().format(new Date(item.date_published))}} |</span>
                <span v-if="item.start">case started: {{new Intl.DateTimeFormat().format(new Date(item.start))}} |</span>
                <span v-if="item.end">case ended: {{new Intl.DateTimeFormat().format(new Date(item.end))}}</span>
              </small></div>
              <div v-if="item.loss" class="mb-0"><strong>USD {{new Intl.NumberFormat().format(item.loss)}}</strong></div>
              <div class="mb-0" v-html="item.description"> </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'CaseSimple',
  props: ['item', 'username'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  }
}
</script>
