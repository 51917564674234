export default {
  files: [
    {
      id: 'main',
      name: 'main file'
    },
    {
      id: 'mainOld',
      name: 'main file old version'
    },
    {
      id: 'risks',
      name: 'risk types',
      columns: ['id', 'type', 'definition', 'function']
    },
    {
      id: 'policies',
      name: 'policies',
      columns: ['name', 'owner', 'reviewed']
    },
    {
      id: 'procedures',
      name: 'procedures',
      columns: ['name', 'owner', 'reviewed']
    },
    {
      id: 'caps',
      name: 'open CAPs',
      columns: ['MCRP', 'number', 'name', 'date']
    },
    {
      id: 'controlsscope',
      name: 'controls scoping',
      columns: ['id', 'name', 'risk', 'scope']
    }
  ]
}
